import React, { useState } from 'react';
import { Layout, Row, Col, Typography, Card, message, Image } from 'antd';
import { marked } from 'marked'; 
import DOMPurify from 'dompurify';
import PdfUploader from './PdfUploader/PdfUploader';
import { useAuth } from '../../auth/AuthContext';
const { Content } = Layout;
const { Title } = Typography;

const EerlyInsights = () => {
    const [fileList, setFileList] = useState([]);
    const [response, setResponse] = useState(null);
    const [summary, setSummary] = useState();
    const [insights, setInsights] = useState();
    const [loading, setLoading] = useState(false);
    const [imageURL, setImageURL] = useState();

    const auth = useAuth();
    const handleUpload = async (file) => {
        const formData = new FormData();
        formData.append('files', file);

        try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/get_eerly_insights`, {
            headers: {
                'Authorization': `Bearer ${auth.user['access_token']}`,
            },
            method: 'POST',
            body: formData,
        });
        const data = await res.json();
        setResponse(data);
        setSummary(data.summary);
        setInsights(data.insights);
        setImageURL(data.wordcloud);
        message.success('File uploaded and processed successfully.');
        } catch (error) {
        console.error('Error uploading file:', error);
        message.error('Error uploading file. Please try again.');
        }
        setLoading(false);
    };

    const markdownToHtml = (markdown) => {
        const rawHtml = marked.parse(markdown);
        return DOMPurify.sanitize(rawHtml);
    };

    return (
        <Content style={{ overflow: 'initial' }} className="site-layout-background mobile-layout-margin">
        <Row>
            <Col span={24}>
            <Title level={3} style={{ marginTop: 0, marginRight: '10px' }}>Eerly Insights</Title>
            </Col>
        </Row>
        <Row>
            <Col lg={10} sm={24} xs={24} style={{paddingRight: "10px"}}>
            <Card>
                <div style={{ padding: "20px" }}>
                <Title level={4} style={{ marginTop: 0 }}>Upload PDF</Title>
                <PdfUploader onUpload={handleUpload} fileList={fileList} setFileList={setFileList} loading={loading} setLoading={setLoading} />
                </div>
            </Card>
            {summary && summary.length > 0 && 
            <Card title="Summary" style={{marginTop: "15px", padding: "20px"}}>
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(summary) }} />
            </Card>
            }
            {insights && insights.length > 0 && 
            <Card title="Insights" style={{marginTop: "15px", marginBottom: "15px", padding: "20px"}}>
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(insights) }} />
            </Card>
            }
            </Col>
            <Col lg={14} sm={24} xs={24} style={{paddingLeft: "10px"}}>
            
            {imageURL && imageURL.length > 0 && 
            <Card title="Wordcloud" style={{padding: "20px"}}>
                <Image alt="preview" src={`data:image/jpeg;base64,${imageURL}`} />
            </Card>
            }
            </Col>
        </Row>
        </Content>
    );
};

export default EerlyInsights;
