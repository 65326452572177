import React, { useEffect, useState } from 'react';
import { Layout, Menu, List, Avatar, Row, Col, Spin, message } from 'antd';
import { marked } from 'marked'; 
import DOMPurify from 'dompurify';
import axios from 'axios';
import { useAuth } from '../../../auth/AuthContext';
import DummyFeedback from '../../../DummyComponent/DummyFeedback/DummyFeedback';
import CopytoClipboard from '../../ButtonComponents/CopytoClipboard';
import botAvatar from '../../../assets/images/favicon.png';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

const { Content, Sider } = Layout;

const ChatHistory = ({ user }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_history`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log("Result: ", result)
        setData(result);
        setLoading(false);
        if (result.length > 0) {
          setSelectedSession(result[0]);
          setSelectedKey(result[0]._id);
        }
      } catch (error) {
        message.error('Failed to fetch records');
        setLoading(false);
      }
    };

    fetchData();
  }, [auth.user]);

  const markdownToHtml = (markdown) => {
    const rawHtml = marked.parse(markdown);
    return DOMPurify.sanitize(rawHtml);
  };

  const fetchProjectPlan = async (llmResponse) => {
    setLoading(true);
    try {
      const apiEndpoint = "project_plan";
      
      const formData = new FormData();
      formData.append("generic_project_plan", llmResponse);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/${apiEndpoint}`, formData, {
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'project_plan.xls');
      document.body.appendChild(link);
      link.click();
      link.remove();      
      message.success('Project plan downloaded successfully!');
    } catch (error) {
      message.error('Failed to download project plan');
    }
    setLoading(false);
  }

  const handleMenuClick = (session) => {
    setSelectedSession(session);
    setSelectedKey(session._id);
  };

  const renderMessage = (message, index, role) => {
    return (
      <List.Item key={index} className={`chat-message ${role} custom-list-item`} style={{ padding: "10px", borderRadius: "10px" }}>
        <List.Item.Meta
          avatar={
            role === 'assistant' ? (
              <Avatar src={botAvatar} style={{ backgroundColor: "#000000", padding: "5px" }} className="avatar-left" />
            ) : (
              <Avatar className="avatar-right" style={{ backgroundColor: "#fff", color: "#0074D9", marginRight: "10px" }}>
                {user.name.split(" ").length > 1 
                  ? `${user.name.split(" ")[0][0]}${user.name.split(" ")[1][0]}` 
                  : `${user.name.split(" ")[0][0]}${user.name.split(" ")[0][1]}`}
              </Avatar>
            )
          }
          title={role === 'user' ? 'You' : 'Eerly AI Brain'}
          description={
            role === 'assistant' ? (
              <div>
                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(message) }} />
                <Row>
                  <Col lg={12} md={12} sm={12} xs={4}>
                    <div style={{ justifyContent: 'flex-start' }}>
                      <CopytoClipboard text={message} />
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={20}>
                    <DummyFeedback/>
                  </Col>
                </Row>
              </div>
            ) : (
              message
            )
          }
        />
      </List.Item>
    );
  };

  const getSessionTitle = (session) => {
    const firstMessage = session.messages[0]?.human || "No message";
    const firstSentence = firstMessage.split('. ')[0];
    return firstSentence.length > 40 ? `${firstSentence.substring(0, 40)}...` : firstSentence;
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ height: '80vh', overflowY: "auto", boxShadow: "0px 0px 40px -1px rgba(176,176,176,1)", borderRadius: "10px" }}>
      <Layout>
        {!collapsed && (
          <Sider 
            width={300} 
            collapsible 
            collapsed={collapsed} 
            onCollapse={toggleCollapsed} 
            trigger={null} 
            style={{ position: 'relative' }}
          >
            <Menu
              theme="light"
              mode="inline"
              style={{ height: '100%', borderRight: 0, paddingTop: "30px" }}
              selectedKeys={[selectedKey]}
            >
              {data.map((session) => (
                <Menu.Item key={session._id} onClick={() => handleMenuClick(session)}>
                  {getSessionTitle(session)}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
        )}
        <Layout style={{ padding: '10px' }}>
          <div 
            className="trigger" 
            style={{ position: 'absolute', top: 10, left: 10, cursor: 'pointer', zIndex: 1 }} 
            onClick={toggleCollapsed}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
          <Content
            style={{
              padding: 24,
              margin: 0,
              maxHeight: "80vh", 
              overflowY: "auto"
            }}
          >
            {loading ? (
              <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
            ) : (
              selectedSession && (
                <List
                  className="chat-list"
                  dataSource={selectedSession.messages}
                  renderItem={(item, index) => (
                    <div key={index}>
                      {renderMessage(item.human, index * 2, 'user')}
                      {renderMessage(item.ai, index * 2 + 1, 'assistant')}
                    </div>
                  )}
                />
              )
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ChatHistory;
