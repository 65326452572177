// src/App.tsx
import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import LayoutWithLeftMenu from "./components/LayoutWithLeftMenu/LayoutWithLeftMenu";
// import Dashboard from './components/Dashboard/Dashboard';
// import ChatAI from './components/Chat/ChatAI/ChatAI';
import ChatWithData from "./components/Chat/ChatWithData/ChatWithData";
// import Copilot from './components/Copilot/Copilot'
import "./App.css";
import { useAuth } from "./auth/AuthContext";
import Subscribe from "./components/Subscribe/Subscribe";
// import ResetPassword from './components/ResetPassword/ResetPassword';
import CheckoutForm from "./components/CheckoutForm/CheckoutForm";
import Return from "./components/Return/Return";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ResetPassword from "./components/Password/OTPValidation/OTPValidation";
import ForgotPassword from "./components/Password/ForgotPassword/ForgotPassword";
import VerifyEmail from "./components/Signup/VerifyEmail/VerifyEmail";
import VerifiedEmail from "./components/Signup/VerifiedEmail/VerifiedEmail";
import EerlyInsights from "./components/EerlyInsights/EerlyInsights";
import SuggetionAndFaq from "./components/SuggetionAndFaq/SuggetionAndFaq";
import HcPolicies from "./components/HcPolicies/HcPolicies";
import GenerativeHumanAI from "./components/GenerativeHumanAI/GenerativeHumanAI";

const initialMessages = [
  {
    id: 1,
    content: "Hello, What can I do for you?",
    role: "assistant",
    timestamp: new Date().toLocaleString(),
  },
];

function App() {
  const auth = useAuth();
  const [messageList, setMessageList] = useState([]);
  const [suggestedQuestionsOn, setSuggestedQuestionsOn] = useState(false);
  const [sapAiMsgList, setSapAiMsgList] = useState(initialMessages);
  const [sapAiSuggetionQueList, setSapAiSuggetionQueList] = useState([]);
  const [sapHcPoliciesList, setSapHcPoliciesList] = useState(initialMessages);
  const [humanAiList, setHumanAiList] = useState(initialMessages);
  const [isFirstQuestionChatAI, setIsFirstQuestionChatAI] = useState(true);
  const [chatWithAISessionID, setChatWithAISessionID] = useState(0);
  const [isFirstQuestionChatKB, setIsFirstQuestionChatKB] = useState(true);
  const [chatWithKBSessionID, setChatWithKBSessionID] = useState(0);

  const [isFirstQuestionHC, setIsFirstQuestionHC] = useState(true);
  const [sessionID, setSessionID] = useState(0);

  return (
    <div>
      <Routes>
        <Route
          path="/login"
          element={
            <Login
              setMessageList={setMessageList}
              setSuggestedQuestionsOn={setSuggestedQuestionsOn}
              setSapAiMsgList={setSapAiMsgList}
              setSapAiSuggetionQueList={setSapAiSuggetionQueList}
              setSapHcPoliciesList={setSapHcPoliciesList}
              initialMessages={initialMessages}
            />
          }
        />
        {/* <Route path="/signup" element={<Signup />}  /> */}
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/email-verified" element={<VerifiedEmail />} />
        {/* <Route path="/subscribe" element={<Subscribe />}  /> */}
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/return" element={<Return />} />
        {/* <Route path="/reset-password" element={<ProtectedRoute><ResetPassword /></ProtectedRoute>} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/" element={<ResetPassword />} />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          element={
            <ProtectedRoute>
              <LayoutWithLeftMenu
                messageList={messageList}
                setMessageList={setMessageList}
                suggestedQuestionsOn={suggestedQuestionsOn}
                setSuggestedQuestionsOn={setSuggestedQuestionsOn}
              />
            </ProtectedRoute>
          }
        >
          {/* <Route path="/overview" element={<Dashboard />} /> */}
          {/* <Route path="/chat-ai" element={<ChatAI />} /> */}
          <Route
            path="/sap-consultant"
            element={
              <ChatWithData
                messageList={messageList}
                setMessageList={setMessageList}
                suggestedQuestionsOn={suggestedQuestionsOn}
                setSuggestedQuestionsOn={setSuggestedQuestionsOn}
                sapAiMsgList={sapAiMsgList}
                setSapAiMsgList={setSapAiMsgList}
                sapAiSuggetionQueList={sapAiSuggetionQueList}
                setSapAiSuggetionQueList={setSapAiSuggetionQueList}
                isFirstQuestionChatAI={isFirstQuestionChatAI}
                setIsFirstQuestionChatAI={setIsFirstQuestionChatAI}
                chatWithAISessionID={chatWithAISessionID}
                setChatWithAISessionID={setChatWithAISessionID}
                isFirstQuestionChatKB={isFirstQuestionChatKB}
                setIsFirstQuestionChatKB={setIsFirstQuestionChatKB}
                chatWithKBSessionID={chatWithKBSessionID}
                setChatWithKBSessionID={setChatWithKBSessionID}
              />
            }
          />
          <Route path="/self-service-faq" element={<SuggetionAndFaq />} />
          <Route
            path="/hc-policies-assistant"
            element={
              <HcPolicies
                sapHcPoliciesList={sapHcPoliciesList}
                setSapHcPoliciesList={setSapHcPoliciesList}
                isFirstQuestionHC={isFirstQuestionHC}
                setIsFirstQuestionHC={setIsFirstQuestionHC}
                sessionID={sessionID}
                setSessionID={setSessionID}
              />
            }
          />
          <Route
            path="/generative-ai-for-human-resource"
            element={
              <GenerativeHumanAI
                humanAiList={humanAiList}
                setHumanAiList={setHumanAiList}
              />
            }
          />
          <Route path="/eerly-insights" element={<EerlyInsights />} />
          {/* <Route path="/rfp-copilot" element={<Copilot copilotType="rfp" />} />
                <Route path="/work-order-copilot" element={<Copilot copilotType="sow" />} />
                <Route path="/rom-copilot" element={<Copilot copilotType="rom" />} /> */}
        </Route>
        <Route
          path="/"
          element={
            auth.user ? (
              <Navigate to="hc-policies-assistant" />
            ) : (
              <Login
                setMessageList={setMessageList}
                setSuggestedQuestionsOn={setSuggestedQuestionsOn}
                setSapAiMsgList={setSapAiMsgList}
                setSapAiSuggetionQueList={setSapAiSuggetionQueList}
                setSapHcPoliciesList={setSapHcPoliciesList}
                initialMessages={initialMessages}
              />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
