// src/components/LayoutWithLeftMenu.jsx
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import {
    Layout,
    Avatar,
    Space,
    Typography,
    Spin,
    Row,
    Col,
    Alert,
    Tooltip,
    Switch,
} from "antd";
import { useAuth } from "../../auth/AuthContext";
import AppFooter from "../AppFooter/AppFooter";
import { jwtDecode } from "jwt-decode";
import "./LayoutWithLeftMenu.css";
import DownloadButton from "../ButtonComponents/DownloadButton";
import Title from "antd/es/typography/Title";
const { Header } = Layout;

const LayoutWithLeftMenu = ({
    messageList,
    setMessageList,
    suggestedQuestionsOn,
    setSuggestedQuestionsOn,
}) => {
    const [collapsed, setCollapsed] = useState(window.innerWidth <= 600);
    const [userDetails, setUserDetails] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/read_user_details`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${auth.user["access_token"]}`,
                        },
                    }
                );
                if (response.ok) {
                    const data = await response.json();

                    localStorage.setItem("userDetails", JSON.stringify(data));
                    setUserDetails(data);
                } else {
                    setUserDetails(null);
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (auth.user && auth.user["access_token"]) {
            const decodedToken = jwtDecode(auth.user["access_token"]);
            const currentTime = Date.now() / 1000; // Convert to seconds

            // Use optional chaining and nullish coalescing to handle potential undefined 'exp'
            const expirationTime = decodedToken.exp ?? 0; // Provide a fallback value (0 or another sensible default)

            const timeUntilExpiration = (expirationTime - currentTime) * 1000; // Convert back to milliseconds

            const logoutTimer = setTimeout(() => {
                auth.logout();
                navigate("/login");
            }, timeUntilExpiration);

            return () => clearTimeout(logoutTimer); // Clear the timer if the component unmounts
        }
    }, [auth.user]);

    useEffect(() => {
        if (!initialized && userDetails) {
            if (userDetails.plan !== "Free" || userDetails.role === "admin") {
                setSuggestedQuestionsOn(true);
            }
            setInitialized(true);
        }
    }, [userDetails, initialized]);

    const onChangeSwitch = (checked) => {
        setSuggestedQuestionsOn(checked);
    };

    return (
        <div>
            {/* <Row>
          <Col xs={24}>
            <Alert message="Eerly AI Brain is currently down for scheduled maintenance. We'll be back soon. Thank you for your patience!" type="error" style={{position: "fixed", width: "100%", top: 0, zIndex: 1056}} />
          </Col>
        </Row> */}
            <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
            {!userDetails ? (
                <div style={{ textAlign: "center", padding: "30px" }}>
                    <Spin tip="Loading" size="large">
                        <div className="content" />
                    </Spin>
                </div>
            ) : (
                <Layout
                    className={`layout-part ${collapsed ? "mobile-sidebar-width" : ""}`}
                    style={
                        collapsed
                            ? { minHeight: "100vh" }
                            : { marginLeft: 290, minHeight: "100vh" }
                    }
                >
                    <Header className="header-part">
                        <Space>
                            <Avatar
                                size={"small"}
                                style={{ backgroundColor: "#0074D9", marginBottom: "3%" }}
                            >
                                {userDetails?.name?.split(" ").length > 1
                                    ? `${userDetails?.name?.split(" ")[0][0]}${userDetails?.name?.split(" ")[1][0]
                                    }`
                                    : `${userDetails?.name?.split(" ")[0][0]}${userDetails?.name?.split(" ")[0][1]
                                    }`}
                            </Avatar>
                            <Typography.Text strong>{userDetails?.name} {userDetails?.reference_bu_field && `(${userDetails?.reference_bu_field})`}</Typography.Text>
                        </Space>
                        {location?.pathname === '/sap-consultant' &&
                            <Space>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: 'center'
                                    }}
                                >
                                    <div className="prompt-suggetions-main">
                                        <Title className="prompt-suggetions-title" level={5}>Prompt Suggestion</Title>
                                        <Switch
                                            onChange={onChangeSwitch}
                                            checked={suggestedQuestionsOn}
                                            //   checkedChildren="Prompt Suggestion On"
                                            //   unCheckedChildren="Prompt Suggestion Off"
                                            className="desktop-view-inline"
                                            size="small"
                                        // disabled={
                                        //     userDetails && userDetails.plan === "Free" && userDetails.role !== "admin"
                                        // }
                                        />
                                    </div>
                                    <Tooltip
                                        placement="top"
                                        title="Prompt Suggestion"
                                        className="mobile-view"
                                    >
                                        <Switch
                                            onChange={onChangeSwitch}
                                            checked={suggestedQuestionsOn}
                                            checkedChildren="On"
                                            unCheckedChildren="Off"
                                            style={{ marginRight: "15px", marginTop: "5px" }}
                                            className="mobile-view"
                                        // disabled={
                                        //     userDetails && userDetails.plan === "Free" && userDetails.role !== "admin"
                                        // }
                                        />
                                    </Tooltip>
                                    <DownloadButton
                                        markdownText={""}
                                        messages={messageList}
                                        disabled={
                                            userDetails &&
                                            userDetails.plan !== "Tier 3" &&
                                            userDetails.role !== "admin"
                                        }
                                    />
                                </div>
                            </Space>
                        }
                    </Header>
                    <Outlet /> {/* This is where nested routes will be rendered */}
                    <AppFooter width={"92%"} rightAlign={1} />
                </Layout>
            )}
        </div>
    );
};

export default LayoutWithLeftMenu;
