// src/auth/AuthContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import {message} from 'antd';

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || 'null'));
  const fetchUserDetails = async (access_token) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/read_user_details`, {
                method: 'GET',
                headers: { 
                    'Authorization': `Bearer ${access_token}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem("userDetails", JSON.stringify(data));   
            }
            
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };
  const login = async (username, password) => {
    try {
        const formBody = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accept': 'application/json'
            },
            body: formBody,
        });
        if (!response.ok) {
          if (response.status === 400) { 
            // message.error("Account credentials expired, please create a new one.");
            throw new Error("Account credentials expired, please create a new one.");
          } else if (response.status === 401){
            // message.error('Invalid username or password');
            throw new Error('Invalid username or password');
          } else if (response.status === 404) { 
            // message.error(`No user found with this ${username} email`);
            throw new Error(`No user found with this ${username} email`);
          } else{
            // message.error('Login failed');
            throw new Error('Login failed');
          }
        }
        const data = await response.json();
        localStorage.setItem('user', JSON.stringify(data));
        await fetchUserDetails(data.access_token)
        setUser(data);
        return data;
    } catch (error) {
        message.error(String(error));
        console.error("Error logging in:", error);
        throw error;
    }
  };

  const register = async (userDetails) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify(userDetails),
      });

      if (!response.ok) {
        const errorData = await response.json();
        message.error(errorData.detail || 'Registration failed');
        throw new Error(errorData.detail || 'Registration failed');
      }

      const data = await response.json();
      message.success('Registration successful, Please log in.');
      return data;
    } catch (error) {
      message.error("Error registering: " + error);
      console.error("Error registering:", error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userDetails');
    setUser(null);
  };

  const value = { user, login, register, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    message.error('useAuth must be used within an AuthProvider');
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
