import { Avatar, Button, Col, Collapse, Flex, Input, Layout, List, message, Row, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../auth/AuthContext';
import { marked } from "marked";
import DOMPurify from "dompurify";
import {
    SendOutlined,
    AudioOutlined,
    DownOutlined,
    FilePdfOutlined
} from "@ant-design/icons";
import botAvatar from "../../assets/images/favicon.png";
import CopytoClipboard from '../ButtonComponents/CopytoClipboard';
import DummyFeedback from '../../DummyComponent/DummyFeedback/DummyFeedback';
import { DownloadOutlined } from '@mui/icons-material';
import './GenerativeHumanAI.css';
import PdfIcon from "../../assets/images/pdf-icon.webp";


const { Content } = Layout;
const { Title, Link } = Typography;

const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = null;
if (SpeechRecognition) {
    recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.lang = "en-US";
    recognition.interimResults = false;
}

const initialMessages = [
    {
        id: 1,
        content: "Hello, What can I do for you?",
        role: "assistant",
        timestamp: new Date().toLocaleString(),
    },
];

const GenerativeHumanAI = ({ humanAiList, setHumanAiList }) => {
    const auth = useAuth();
    const Panel = Collapse.Panel;
    const userDetails = localStorage.getItem("userDetails");
    const user = userDetails && JSON.parse(userDetails);
    //const [humanAiList, setHumanAiList] = useState(initialMessages);
    const [initialQuestions, setInitialQuestions] = useState([]);
    const [recording, setRecording] = useState(false);
    const [newMessage, setNewMessage] = useState("");
    const [chatInitiated, setChatInitiated] = useState(humanAiList?.length > 1 ? true : false);


    useEffect(() => {
        const scrollToBottom = () => {
            const lastMessageElem = document.querySelector(
                ".chat-list .ant-list-item:last-child"
            );
            if (lastMessageElem) {
                lastMessageElem.scrollIntoView({ behavior: "smooth", block: "end" });
            }
        };

        scrollToBottom();
    }, [humanAiList]);

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const formData = new FormData();
        //         formData.append(
        //             "user_input",
        //             "Generate 3 SAP related questions. Each question should not be more than 10 words long."
        //         );
        //         const response = await fetch(
        //             `${process.env.REACT_APP_API_URL}/hc_policies_initial_questions`,
        //             {
        //                 method: "POST",
        //                 headers: {
        //                     Authorization: `Bearer ${auth.user["access_token"]}`,
        //                 },
        //                 body: formData,
        //             }
        //         );
        //         if (!response.ok) {
        //             throw new Error(`Error: ${response.status}`);
        //         }
        //         const result = await response.json();
        //         setInitialQuestions(result.question_list);
        //     } catch (err) {
        //         message.error(err.message);
        //     }
        // };

        // fetchData();

        if (!recognition) return;

        recognition.onresult = (event) => {
            const transcript = Array.from(event.results)
                .map((result) => result[0])
                .map((result) => result.transcript)
                .join("");
            setNewMessage(transcript);
            setRecording(false);
        };

        recognition.onend = () => {
            setRecording(false);
        };

        recognition.onerror = (event) => {
            console.error("SpeechRecognition error:", event.error);
            setRecording(false);
        };
    }, []);

    useEffect(() => {
        async function handleSendMessageAutomatically() {
            // You can await here
            const response = await handleSendMessage();
        }
        handleSendMessageAutomatically();
    }, [chatInitiated]);

    const handleRecord = () => {
        if (!SpeechRecognition) {
            console.error("SpeechRecognition is not supported in this browser.");
            return;
        }
        if (recording) {
            recognition.stop();
            setRecording(false);
        } else {
            recognition.start();
            setRecording(true);
        }
    };

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;
        const nextYouMessage = {
            id: humanAiList.length + 1,
            content: newMessage,
            role: "user",
            timestamp: new Date().toLocaleString(),
        };

        // Temporary loading message for bot
        const loadingMessage = {
            id: humanAiList.length + 2,
            content: "Loading bot's response...", // This could be replaced with a spinner or any placeholder
            role: "assistant",
            timestamp: new Date().toLocaleString(),
        };

        // Update state with user message and temporary loading message
        setHumanAiList((humanAiList) => [...humanAiList, nextYouMessage, loadingMessage]);
        setNewMessage("");
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/general_hr_questions?query=${newMessage.trim()}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${auth.user["access_token"]}`,
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                const botMessage = {
                    id: loadingMessage.id,
                    content: data?.result,
                    page_content: data?.page_content,
                    source: data?.source,
                    role: "assistant",
                    timestamp: new Date().toLocaleString(),
                    pdf_doc_names: data?.pdf_doc_names,
                    answer: data?.answer
                };

                // Replace the loading message with actual bot response
                setHumanAiList((humanAiList) =>
                    humanAiList.map((message) =>
                        message.id === loadingMessage.id ? botMessage : message
                    )
                );

            } else {

            }
        } catch (error) {
            console.error("Fetching bot's response failed:", error);
            const errorMessage = {
                id: loadingMessage.id,
                content: "Failed to load response",
                role: "assistant",
                timestamp: new Date().toLocaleString(),
            };

            // Update loading message with error message
            setHumanAiList((humanAiList) =>
                humanAiList.map((message) =>
                    message.id === loadingMessage.id ? errorMessage : message
                )
            );
        }
    }

    const markdownToHtml = (markdown) => {
        const rawHtml = marked.parse(markdown);
        return DOMPurify.sanitize(rawHtml);
    };

    const startChat = async (question) => {
        setNewMessage(question);
        setChatInitiated(true);
    };

    const detectProjectPlanIntent = (question) => {
        // Define a pattern to match phrases related to asking for a project plan
        const pattern = /\bproject plan\b/i;

        // Test for the pattern in the question
        return pattern.test(question);
    };

    const convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h?.split(" ");

        let [hours, minutes, seconds] = time?.split(":");

        if (hours === "12") {
            hours = "00";
        }

        if (modifier === "PM") {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    };

    return (
        <Content
            style={{ overflow: "initial" }}
            className="site-layout-background mobile-layout-margin"
        >
            <Flex className="suggetion-faq-container" id="scrollableDiv">
                <Title level={2} className="suggetion-faq-title">
                    Sanlam Virtual Assistant
                </Title>
                {!chatInitiated ? (
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{ height: "60vh" }}
                    >
                        <Col
                            span={24}
                            style={{ textAlign: "center", marginBottom: "-25vh" }}
                        >
                            <Title level={3} className='welcome-text'>Hi, Welcome to Generative AI For Human Resource!</Title>
                            {/* <Title level={5}>How can I help you? You can type your questions.</Title> */}
                            {/* {initialQuestions?.length > 0 && (
                                <Row style={{ marginTop: "10px" }}>
                                    {initialQuestions?.map((question, index) => (
                                        <Col xs={24} lg={8} key={question}>
                                            <Button
                                                onClick={() => {
                                                    setChatInitiated(false);
                                                    startChat(question);
                                                }}
                                                hoverable
                                                style={{
                                                    padding: "5px 20px",
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                    borderRadius: "50px",
                                                    fontSize: "12px",
                                                    margin: "10px",
                                                    width: "90%",
                                                }}
                                                className="custom-button"
                                            >
                                                {question}
                                            </Button>
                                        </Col>
                                    ))}
                                </Row>
                            )} */}
                        </Col>
                    </Row>) : (
                    <List
                        className="chat-list"
                        dataSource={humanAiList}
                        renderItem={(item, index) => {
                            const previousMessage = humanAiList[index - 1];
                            const isProjectPlanInPreviousMessage =
                                previousMessage &&
                                detectProjectPlanIntent(previousMessage?.content) &&
                                item?.role === "assistant";
                            //const collapseItem = [{key: index, label: 'Source Citation', children: {page_content: }}];
                            const time = convertTime12to24(item?.timestamp?.split(", ")[1]);
                            const docUrl = encodeURIComponent(`https://sanlamfiles.blob.core.windows.net/faqs/${item?.source}`);
                            const checkUrl = item?.source?.split(".")?.pop();
                            return (
                                <List.Item
                                    key={item?.id}
                                    className={`chat-message ${item.role} custom-list-item`}
                                    style={{ padding: "10px", borderRadius: "10px" }}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            item.role === "assistant" ? (
                                                <Avatar
                                                    src={botAvatar}
                                                    style={{ backgroundColor: "#000000", padding: "5px" }}
                                                    className="avatar-left"
                                                />
                                            ) : (
                                                <Avatar
                                                    className="avatar-right"
                                                    style={{
                                                        backgroundColor: "#fff",
                                                        color: "#0074D9",
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    {user?.name?.split(" ").length > 1
                                                        ? `${user?.name?.split(" ")[0][0]}${user?.name?.split(" ")[1][0]
                                                        }`
                                                        : `${user?.name?.split(" ")[0][0]}${user?.name?.split(" ")[0][1]
                                                        }`}
                                                </Avatar>
                                            )
                                        }
                                        // title={item.role === 'user' ? 'You' : 'SAP AI Consultant'}

                                        description={
                                            item?.content === "Loading bot's response..." ? (
                                                <Spin />
                                            ) : item?.role === "user" ? (
                                                item?.content
                                            ) : typeof item?.content === "object" ? (
                                                <Collapse accordion>
                                                    {item?.content.map((response, index) => (
                                                        <Panel
                                                            header={"Response " + String(Number(index) + 1)}
                                                            key={index + 1}
                                                        >
                                                            <div
                                                                className="message-content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: markdownToHtml(
                                                                        response?.synthesized_response
                                                                    ),
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    textAlign: "right",
                                                                    marginBottom: "10px",
                                                                }}
                                                            >
                                                                <Link
                                                                    href={response.url}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    Source Citation
                                                                </Link>
                                                            </div>
                                                            <Row>
                                                                <Col lg={12} md={12} sm={12} xs={4}>
                                                                    <Flex
                                                                        style={{ justifyContent: "flex-start" }}
                                                                    >
                                                                        <CopytoClipboard
                                                                            text={response?.synthesized_response}
                                                                        />
                                                                    </Flex>
                                                                </Col>
                                                                <Col lg={12} md={12} sm={12} xs={20}>
                                                                    <DummyFeedback />
                                                                </Col>
                                                            </Row>
                                                        </Panel>
                                                    ))}
                                                </Collapse>
                                            ) : (
                                                <div>
                                                    {item?.answer && <p>{item?.answer}</p>}
                                                    {item?.content &&
                                                        <>
                                                            <div
                                                                className="message-content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: markdownToHtml(item?.content),
                                                                }}
                                                            />
                                                            <Row>
                                                                <Col lg={12} md={12} sm={12} xs={4}>
                                                                    <Flex style={{ justifyContent: "flex-start" }}>
                                                                        <CopytoClipboard text={item?.content} />
                                                                        {/* {isProjectPlanInPreviousMessage && (
                                                                    <>
                                                                        {loading ? (
                                                                            <Spin />
                                                                        ) : (
                                                                            <DownloadOutlined
                                                                                style={{ marginRight: "10px" }}
                                                                                onClick={async () =>
                                                                                    await fetchProjectPlan(item.content)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                )} */}
                                                                    </Flex>
                                                                </Col>
                                                                <Col lg={12} md={12} sm={12} xs={20}>
                                                                    <DummyFeedback />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                    {item?.page_content &&
                                                        <Collapse accordion className='source-citation-collapse'>
                                                            <Panel header="Source Citation">
                                                                {checkUrl === 'docx' ?
                                                                    <iframe
                                                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${docUrl}`}
                                                                        style={{ width: '100%', height: '600px' }}
                                                                        frameBorder="0"
                                                                        title="doc-preview"
                                                                    /> :
                                                                    <>
                                                                        <p>{item?.page_content}</p>
                                                                        <p className='source-link'>{item?.source.split("/").pop()}</p>
                                                                    </>
                                                                }
                                                            </Panel>
                                                        </Collapse>
                                                    }
                                                    {item?.pdf_doc_names &&
                                                        <div className='pdf-doc-name-main'>
                                                            <p className='pdf-doc-name-title'>Supporting Documents</p>
                                                            {
                                                                item?.pdf_doc_names?.length > 0 &&
                                                                item?.pdf_doc_names?.map((item) =>
                                                                    <Link className='pdf-doc-name-link' href={`https://sanlamfiles.blob.core.windows.net/faqs/${item}`} target='_blank'>
                                                                        <FilePdfOutlined style={{ fontSize: '20px' }} />
                                                                        <p>{item?.split("/").pop()}</p>
                                                                    </Link>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                    />
                                    <p className="timer-desc">{time}</p>
                                </List.Item>
                            );
                        }}
                    />

                )}
            </Flex>
            <Space.Compact className="ask-query-main">
                <Button
                    //type="primary"
                    className="ask-query-icon audio"
                    onClick={handleRecord}
                    icon={<AudioOutlined />}
                    danger={recording}
                />
                <Input
                    value={newMessage}
                    onChange={handleInputChange}
                    onPressEnter={async () => {
                        setChatInitiated(true);
                        await handleSendMessage();
                    }}
                    placeholder="Ask your query..."
                />
                <Button
                    //type="primary"
                    className="ask-query-icon send"
                    onClick={async () => {
                        setChatInitiated(true);
                        await handleSendMessage();
                    }}
                    icon={<SendOutlined />}
                />
            </Space.Compact>
            <div className='can-make-mistake-text'>
                Sanlam Virtual Assistant can make mistakes occasionally and may not always be precise; kindly verify before proceeding.
            </div>
        </Content>
    )
}

export default GenerativeHumanAI