import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LogoutOutlined,
  RobotOutlined,
  TeamOutlined,
  WechatWorkOutlined,
  CrownOutlined,
  BarChartOutlined,
  OpenAIOutlined
} from '@ant-design/icons';
import { Layout, Menu, Typography, Button, Modal, Tooltip } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import logo from "../../assets/images/Sanlam.png";
import favicon from "../../assets/images/Eerly_logo_white-transparent.png";
import TicketModal from "../TicketModal/TicketModal";
import PricingTableModal from '../PricingTableModal/PricingTableModal';
import './Sidebar.css';

const { Sider } = Layout;

const { Title } = Typography;

const Sidebar = ({ collapsed, setCollapsed }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isInsightsModalOpen, setIsInsightsModalOpen] = useState(false);

  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails && JSON.parse(userDetails);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsInsightsModalOpen(false);
  };

  const handleCancel = () => {
    setIsInsightsModalOpen(false);
  };

  const getSelectedKey = (pathname) => {
    // Map of pathnames to menu item keys
    const keyMap = {
      // '/overview': '1',
      // '/chat-ai': '2',
      //'/chat-with-data': '3',
      '/sap-consultant': '3',
      '/work-order-copilot': '4-3',
      '/rom-copilot': '4-2',
      '/rfp-copilot': '4-1',
      "/hc-policies-assistant": '6',
      "/self-service-faq": '7',
      "/generative-ai-for-human-resource": "8"
    };

    // Return the corresponding key or a default value
    return keyMap[pathname] || '6';
  };

  const handleLogout = () => {
    auth.logout();
    navigate('/login'); // Navigate to login page after logout
  };

  // Define the menu items with icons and TypeScript typings
  const menuItems = [

    {
      key: '6', icon: <BarChartOutlined />, label: <Link to="/hc-policies-assistant"
      >HR Policies and FAQs</Link>
    },
    {
      key: '7', icon: <BarChartOutlined />, label: <Link to="" onClick={(e) => { e.preventDefault(); setIsInsightsModalOpen(true); }}
      >Employee Self Service</Link>
    },
    { key: '8', icon: <OpenAIOutlined />, label: <Link to="/generative-ai-for-human-resource" >Generative AI For Human Resource</Link> },
    { key: '3', icon: <WechatWorkOutlined />, label: <Link to="/sap-consultant">SAP AI Consultant</Link> },
    // { key: '6', icon: <BarChartOutlined />, label: <Link to="/eerly-insights" 
    //   // onClick={(e) => { e.preventDefault(); setIsInsightsModalOpen(true); }}
    //   >Eerly Insights</Link> },
    // {
    //   key: '4',
    //   icon: <RobotOutlined />,
    //   label: 'Co-Pilots',
    //   disabled: user.plan === 'Free' && user.role !== 'admin',
    //   children: [ 
    //     { key: '4-1', label: <Tooltip placement="rightTop" title={"Currently in Beta Mode"}><Link to="/rfp-copilot">RFP Copilot</Link></Tooltip>, disabled: user.plan === 'Free' && user.role !== 'admin' },         
    //     { key: '4-2', label: <Tooltip placement="rightTop" title={"Currently in Beta Mode"}><Link to="/rom-copilot">ROM Copilot</Link></Tooltip>, disabled: user.plan === 'Free' && user.role !== 'admin' },
    //     { key: '4-3', label: <Tooltip placement="rightTop" title={"Currently in Beta Mode"}><Link to="/work-order-copilot">Work Order Copilot</Link></Tooltip>, disabled: user.plan === 'Free' && user.role !== 'admin' },
    //   ],
    // },
    { key: '5', icon: <LogoutOutlined />, label: <Link to="" onClick={handleLogout}>Logout</Link> },
  ];

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        className={`sidebar-main ${collapsed ? 'sidebar-close' : 'sidebar-open'}`}
        width={290}
      >
        {collapsed ?
          <div className='logo-part'>
            <img src={logo} alt="" style={{ width: "50px", height: "auto" }} />
          </div>
          :
          <div className='logo-part'>
            <img src={logo} alt="" />
          </div>
        }
        <Menu theme="dark" defaultSelectedKeys={[getSelectedKey(location.pathname)]} mode="inline" items={menuItems} className='menu-part' />
        {/* {collapsed?<Button type="default" onClick={showModal} style={{position: "absolute", bottom: 60, width: "80%",color: "#0075C9", cursor: "pointer", backgroundColor: "transparent", borderColor: "#0075C9", marginLeft: "10px"}}><TeamOutlined /></Button>:
          <Button type="default" onClick={showModal} style={{position: "absolute", bottom: 60, width: "90%",color: "#0075C9", cursor: "pointer", backgroundColor: "transparent", borderColor: "#0075C9", marginLeft: "10px"}}><TeamOutlined /> Support</Button>}
          {collapsed?
          <>
            <Button type="default" onClick={()=>setIsUpgradeModalOpen(true)} style={{position: "absolute", bottom: 120, width: "80%",color: "#ffd800", cursor: "pointer", backgroundColor: "transparent", borderColor: "#ffd800", marginLeft: "10px"}}><CrownOutlined /></Button>
          </>:
          <>
            <Button type="default" onClick={()=>setIsUpgradeModalOpen(true)} style={{position: "absolute", bottom: 120, width: "90%",color: "#ffd800", cursor: "pointer", backgroundColor: "transparent", borderColor: "#ffd800", marginLeft: "10px"}}><CrownOutlined /> Upgrade Plan</Button>
          </>} */}
        <div style={{ position: "absolute", bottom: 20, width: "100%", textAlign: "center" }} onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <DoubleRightOutlined style={{ color: "#fff", cursor: "pointer" }} /> : <DoubleLeftOutlined style={{ color: "#fff", zIndex: 1, cursor: "pointer" }} />}
        </div>
      </Sider>
      <TicketModal isModalVisible={isModalOpen} setIsModalVisible={setIsModalOpen} />
      <PricingTableModal visible={isUpgradeModalOpen} setVisible={setIsUpgradeModalOpen} />
      <Modal title="Coming Soon" open={isInsightsModalOpen} onOk={handleOk} onCancel={handleCancel} centered maskClosable={false} keyboard={false}>
        <div style={{ textAlign: "center" }}>
          <Title level={3} style={{ padding: "50px 10px" }}>Keep an eye out for this space</Title>
        </div>
      </Modal>
    </>
  )
}

export default Sidebar;
