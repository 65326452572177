// Chatbot.tsx
import React, { useState, useEffect, useRef } from "react";
import {
  List,
  Input,
  Button,
  Avatar,
  Space,
  Card,
  Typography,
  Collapse,
  Spin,
  Row,
  Col,
  Flex,
  Switch,
  Modal,
  message,
  Upload,
  Alert,
} from "antd";
import CopytoClipboard from "../ButtonComponents/CopytoClipboard";
import DownloadButton from "../ButtonComponents/DownloadButton";
import {
  SendOutlined,
  PaperClipOutlined,
  FilePdfOutlined,
  DownloadOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { marked } from "marked";
import "./Chatbot.css";
import axios from "axios";
import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../auth/AuthContext";
import DummyFeedback from "../../DummyComponent/DummyFeedback/DummyFeedback";
import botAvatar from "../../assets/images/favicon.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { calc } from "antd/es/theme/internal";

const { Title, Link, Text } = Typography;
const Panel = Collapse.Panel;
const { Dragger } = Upload;

// Speech recognition setup
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = null;
if (SpeechRecognition) {
  recognition = new SpeechRecognition();
  recognition.continuous = false;
  recognition.lang = "en-US";
  recognition.interimResults = false;
}

const initialMessages = [
  {
    id: 1,
    content: "Hello, What can I do for you?",
    role: "assistant",
    timestamp: new Date().toLocaleString(),
  },
];

const Chatbot = ({
  setMessageList,
  suggestedQuestionsOn,
  user,
  sapAiMsgList,
  setSapAiMsgList,
  sapAiSuggetionQueList,
  setSapAiSuggetionQueList,
  isFirstQuestionChatAI,
  setIsFirstQuestionChatAI,
  chatWithAISessionID,
  setChatWithAISessionID,
  isFirstQuestionChatKB,
  setIsFirstQuestionChatKB,
  chatWithKBSessionID,
  setChatWithKBSessionID
}) => {
  // const userDetails = localStorage.getItem("userDetails");
  // const user = userDetails ? JSON.parse(userDetails) : {};
  const auth = useAuth();
  const suggetionQueMainRef = useRef();
  //const [sapAiMsgList, setSapAiMsgList] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState("");
  const [chatInitiated, setChatInitiated] = useState(
    sapAiMsgList?.length > 1 ? true : false
  );
  const [value, setValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  //const [sapAiSuggetionQueList, setSapAiSuggetionQueList] = useState([]);
  const [switch1Disabled, setSwitch1Disabled] = useState(false);
  const [switch2Disabled, setSwitch2Disabled] = useState(false);
  // const [isFirstQuestionChatAI, setIsFirstQuestionChatAI] = useState(true);
  // const [chatWithAISessionID, setChatWithAISessionID] = useState(0);
  // const [isFirstQuestionChatKB, setIsFirstQuestionChatKB] = useState(true);
  // const [chatWithKBSessionID, setChatWithKBSessionID] = useState(0);
  const [recording, setRecording] = useState(false);
  const [projectPlan, setProjectPlan] = useState();
  const [loading, setLoading] = useState(false);
  const [initialQuestions, setInitialQuestions] = useState([]);
  const [cardHeight, setCardHeight] = useState(160);

  useEffect(() => {
    const scrollToBottom = () => {
      const lastMessageElem = document.querySelector(
        ".chat-list .ant-list-item:last-child"
      );
      if (lastMessageElem) {
        lastMessageElem.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    };

    scrollToBottom();
  }, [sapAiMsgList]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeSwitch1 = (checked) => {
    if (checked) {
      setValue(1);
      setSwitch2Disabled(true);
    } else {
      setValue(0);
      setSwitch2Disabled(false);
    }
  };
  const onChangeSwitch2 = (checked) => {
    if (checked) {
      setValue(2);
      setSwitch1Disabled(true);
      message.info(
        "This feature is in preview mode and will be continuously improved.",
        5
      );
    } else {
      setValue(0);
      setSwitch1Disabled(false);
    }
  };

  useEffect(() => {
    async function handleSendMessageAutomatically() {
      // You can await here
      const response = await handleSendMessage();
    }
    handleSendMessageAutomatically();
  }, [chatInitiated]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    setSapAiSuggetionQueList([]);
    // User message
    const nextYouMessage = {
      id: sapAiMsgList.length + 1,
      content: newMessage,
      role: "user",
      timestamp: new Date().toLocaleString(),
    };

    // Temporary loading message for bot
    const loadingMessage = {
      id: sapAiMsgList.length + 2,
      content: "Loading bot's response...", // This could be replaced with a spinner or any placeholder
      role: "assistant",
      timestamp: new Date().toLocaleString(),
    };

    // Update state with user message and temporary loading message
    setSapAiMsgList((sapAiMsgList) => [
      ...sapAiMsgList,
      nextYouMessage,
      loadingMessage,
    ]);
    setNewMessage("");

    setMessageList((sapAiMsgList) => [
      ...sapAiMsgList,
      nextYouMessage,
      loadingMessage,
    ]);

    try {
      const botResponse = await fetchBotResponse(
        newMessage,
        sapAiMsgList.length + 2
      );
      const botMessage = {
        id: loadingMessage.id,
        content: botResponse,
        role: "assistant",
        timestamp: new Date().toLocaleString(),
      };

      // Replace the loading message with actual bot response
      setSapAiMsgList((sapAiMsgList) =>
        sapAiMsgList.map((message) =>
          message.id === loadingMessage.id ? botMessage : message
        )
      );
      setMessageList((sapAiMsgList) =>
        sapAiMsgList.map((message) =>
          message.id === loadingMessage.id ? botMessage : message
        )
      );
    } catch (error) {
      console.error("Fetching bot's response failed:", error);
      const errorMessage = {
        id: loadingMessage.id,
        content: "Failed to load response",
        role: "assistant",
        timestamp: new Date().toLocaleString(),
      };

      // Update loading message with error message
      setSapAiMsgList((sapAiMsgList) =>
        sapAiMsgList.map((message) =>
          message.id === loadingMessage.id ? errorMessage : message
        )
      );
      setMessageList((sapAiMsgList) =>
        sapAiMsgList.map((message) =>
          message.id === loadingMessage.id ? errorMessage : message
        )
      );
    }
  };

  const detectProjectPlanIntent = (question) => {
    // Define a pattern to match phrases related to asking for a project plan
    const pattern = /\bproject plan\b/i;

    // Test for the pattern in the question
    return pattern.test(question);
  };

  const fetchBotResponse = async (query, id) => {
    try {
      let apiEndpoint = "chat_with_ai";
      if (uploaded) apiEndpoint = "chat_with_data";
      if (value === 1) apiEndpoint = "bing_web_search";
      if (value === 2) apiEndpoint = "chat_with_knowledge_base";

      let formData = new FormData();
      formData.append("query", query);
      if (apiEndpoint === "chat_with_ai") {
        formData.append("is_first_question_chat_ai", isFirstQuestionChatAI);
        const aiSessionID = uuidv4();
        if (chatWithAISessionID === 0) {
          setChatWithAISessionID(aiSessionID);
        }
        formData.append(
          "session_id",
          chatWithAISessionID == 0 ? aiSessionID : chatWithAISessionID
        );
      } else if (apiEndpoint === "chat_with_knowledge_base") {
        formData.append(
          "is_first_question_chat_knowledge_base",
          isFirstQuestionChatKB
        );
        const kbSessionID = uuidv4();
        if (chatWithKBSessionID === 0) {
          setChatWithKBSessionID(kbSessionID);
        }
        formData.append(
          "session_id",
          chatWithKBSessionID === 0 ? kbSessionID : chatWithKBSessionID
        );
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${apiEndpoint}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth.user["access_token"]}`,
          },
          body: formData,
        }
      );

      const isProjectPlan = detectProjectPlanIntent(query);
      if (
        apiEndpoint === "chat_with_ai" ||
        apiEndpoint === "chat_with_knowledge_base"
      ) {
        if (response.body) {
          const reader = response.body.getReader();
          let completeResponse = "";
          // Read the stream
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            // Decode the stream chunk
            const chunk = new TextDecoder().decode(value);
            completeResponse += chunk;
            if (apiEndpoint === "chat_with_ai") setIsFirstQuestionChatAI(false);
            else if (apiEndpoint === "chat_with_knowledge_base")
              setIsFirstQuestionChatKB(false);
            // Update the loading message with new content
            setSapAiMsgList((sapAiMsgList) =>
              sapAiMsgList.map((message) =>
                message.id === id
                  ? { ...message, content: completeResponse }
                  : message
              )
            );
          }
          if (isProjectPlan) {
            setProjectPlan(completeResponse);
          }
          suggestedQuestionsOn &&
            fetchSuggestedQuestions(query + "\n" + completeResponse);
          // Optionally, replace the loading message with the complete response or leave it as is
          return completeResponse; // Return the complete response after all chunks are processed
        }
      } else {
        if (response.ok) {
          const data = await response.json();
          if (data.error) {
            return data.error;
          }
          if (isProjectPlan) {
            setProjectPlan(data.response);
          }
          suggestedQuestionsOn &&
            fetchSuggestedQuestions(query + "\n" + data.response);
          return data.response;
        }
      }
    } catch (err) {
      return err;
    }
  };

  const fetchProjectPlan = async (llmResponse) => {
    setLoading(true);
    try {
      const apiEndpoint = "project_plan";

      const formData = new FormData();
      formData.append("generic_project_plan", llmResponse);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/${apiEndpoint}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.user["access_token"]}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "project_plan.xls");
      document.body.appendChild(link);
      link.click();
      link.remove();
      message.success("Project plan downloaded successfully!");
    } catch (error) {
      message.error("Failed to download project plan");
    }
    setLoading(false);
  };

  const fetchSuggestedQuestions = async (query) => {
    try {
      const apiEndpoint = "suggest_questions";
      const formData = new FormData();
      formData.append("query", query);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${apiEndpoint}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth.user["access_token"]}`,
          },
          body: formData,
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSapAiSuggetionQueList(data.questionList);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append(
          "user_input",
          "Generate 3 SAP related questions. Each question should not be more than 10 words long."
        );
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/generate_initial_questions`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${auth.user["access_token"]}`,
            },
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const result = await response.json();
        setInitialQuestions(result.question_list);
      } catch (err) {
        message.error(err.message);
      }
    };

    fetchData();
    if (!recognition) return;

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      setNewMessage(transcript);
      setRecording(false);
    };

    recognition.onend = () => {
      setRecording(false);
    };

    recognition.onerror = (event) => {
      console.error("SpeechRecognition error:", event.error);
      setRecording(false);
    };
  }, []);

  const handleRecord = () => {
    if (!SpeechRecognition) {
      console.error("SpeechRecognition is not supported in this browser.");
      return;
    }
    if (recording) {
      recognition.stop();
      setRecording(false);
    } else {
      recognition.start();
      setRecording(true);
    }
  };

  const markdownToHtml = (markdown) => {
    const rawHtml = marked.parse(markdown);
    return DOMPurify.sanitize(rawHtml);
  };
  const startChat = async (question) => {
    setNewMessage(question);
    setChatInitiated(true);
  };

  const handleFileChange = (info) => {
    const { file, fileList: currentFileList } = info;
    const isLessThan20MB = file.size / 1024 / 1024 < 5;
    if (!isLessThan20MB) {
      message.error("File must be smaller than 5MB!");
      return;
    }
    // Only update the state if the file size is under 20MB
    setFileList(currentFileList.filter((file) => file.size / 1024 / 1024 < 5));
    // let fileList = [...info.fileList];
    // setFileList(fileList);
  };

  const handleSubmit = async () => {
    if (fileList.length === 0) {
      message.warning("Please upload a file before submitting.");
      return;
    }
    setIsSubmitting(true);
    // Prepare the data for submission
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${auth.user["access_token"]}`,
        },
        body: formData,
      });
      const data = await response.json();
      setSapAiSuggetionQueList(
        data
          ?.split(/question:/i)
          ?.slice(1)
          ?.map((question) => question.trim())
      );
      setUploaded(true);
      setIsModalOpen(false);
      message.success("Upload completed!");
      message.info("Chat with your data don't have memory support yet!");
    } catch (error) {
      message.error("An error occurred during uploading.");
      console.error("Error:", error);
    }
    setIsSubmitting(false);
  };

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h?.split(" ");

    let [hours, minutes, seconds] = time?.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  const renderSlides = () =>
    sapAiSuggetionQueList?.map((question, index) => (
      <Button
        key={question}
        onClick={() => {
          setChatInitiated(false);
          startChat(question);
        }}
        hoverable
        style={{
          padding: "5px 20px",
          textAlign: "center",
          cursor: "pointer",
          borderRadius: "50px",
          fontSize: "12px",
          width: "100%",
        }}
        className="custom-button"
      >
        {question}
      </Button>
    ));

  useEffect(() => {
    if (suggetionQueMainRef?.current?.clientHeight) {
      setCardHeight(suggetionQueMainRef?.current?.clientHeight + 210);
    } else {
      setCardHeight(178);
    }
  }, [sapAiSuggetionQueList]);

  console.log(cardHeight, "cardHeight");

  return (
    <>
      <div
        className="chat-container"
        style={{ height: `calc(100vh - ${cardHeight}px)` }}
      >
        <Title level={2} className="chat-container-title">
          Sanlam Virtual Assistant
        </Title>
        {!chatInitiated ? (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ height: "60vh" }}
          >
            <Col
              span={24}
              style={{ textAlign: "center", marginBottom: "-25vh" }}
            >
              <Title level={3} className="welcome-text">Hi, Welcome to SAP AI Consultant!</Title>
              {/* <Title level={5}>How can I help you? You can type your questions.</Title> */}
              {initialQuestions.length > 0 && (
                <Row style={{ marginTop: "10px" }}>
                  {initialQuestions.map((question, index) => (
                    <Col xs={24} lg={8}>
                      <Button
                        onClick={() => {
                          setChatInitiated(false);
                          startChat(question);
                        }}
                        hoverable
                        style={{
                          padding: "5px 20px",
                          textAlign: "center",
                          cursor: "pointer",
                          borderRadius: "50px",
                          fontSize: "12px",
                          margin: "10px",
                          width: "90%",
                        }}
                        className="custom-button"
                      >
                        {question}
                      </Button>
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
        ) : (
          <List
            className="chat-list"
            dataSource={sapAiMsgList}
            renderItem={(item, index) => {
              const previousMessage = sapAiMsgList[index - 1];
              const isProjectPlanInPreviousMessage =
                previousMessage &&
                detectProjectPlanIntent(previousMessage?.content) &&
                item.role === "assistant";
              const time = convertTime12to24(item?.timestamp?.split(", ")[1]);
              return (
                <List.Item
                  key={item.id}
                  className={`chat-message ${item.role} custom-list-item`}
                  style={{ padding: "10px", borderRadius: "10px" }}
                >
                  <List.Item.Meta
                    avatar={
                      item.role === "assistant" ? (
                        <Avatar
                          src={botAvatar}
                          style={{ backgroundColor: "#000000", padding: "5px" }}
                          className="avatar-left"
                        />
                      ) : (
                        <Avatar
                          className="avatar-right"
                          style={{
                            backgroundColor: "#fff",
                            color: "#0074D9",
                            marginRight: "10px",
                          }}
                        >
                          {user?.name?.split(" ").length > 1
                            ? `${user?.name?.split(" ")[0][0]}${user?.name?.split(" ")[1][0]
                            }`
                            : `${user?.name?.split(" ")[0][0]}${user?.name?.split(" ")[0][1]
                            }`}
                        </Avatar>
                      )
                    }
                    // title={item.role === 'user' ? 'You' : 'SAP AI Consultant'}

                    description={
                      item.content === "Loading bot's response..." ? (
                        <Spin />
                      ) : item.role === "user" ? (
                        item.content
                      ) : typeof item.content === "object" ? (
                        <Collapse accordion>
                          {item.content.map((response, index) => (
                            <Panel
                              header={"Response " + String(Number(index) + 1)}
                              key={index + 1}
                            >
                              <div
                                className="message-content"
                                dangerouslySetInnerHTML={{
                                  __html: markdownToHtml(
                                    response.synthesized_response
                                  ),
                                }}
                              />
                              <div
                                style={{
                                  textAlign: "right",
                                  marginBottom: "10px",
                                }}
                              >
                                <Link
                                  href={response.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Source Citation
                                </Link>
                              </div>
                              <Row>
                                <Col lg={12} md={12} sm={12} xs={4}>
                                  <Flex
                                    style={{ justifyContent: "flex-start" }}
                                  >
                                    <CopytoClipboard
                                      text={response.synthesized_response}
                                    />
                                  </Flex>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={20}>
                                  <DummyFeedback />
                                </Col>
                              </Row>
                            </Panel>
                          ))}
                        </Collapse>
                      ) : (
                        <div>
                          <div
                            className="message-content"
                            dangerouslySetInnerHTML={{
                              __html: markdownToHtml(item.content),
                            }}
                          />
                          <Row>
                            <Col lg={12} md={12} sm={12} xs={4}>
                              <Flex style={{ justifyContent: "flex-start" }}>
                                <CopytoClipboard text={item.content} />
                                {isProjectPlanInPreviousMessage && (
                                  <>
                                    {loading ? (
                                      <Spin />
                                    ) : (
                                      <DownloadOutlined
                                        style={{ marginRight: "10px" }}
                                        onClick={async () =>
                                          await fetchProjectPlan(item.content)
                                        }
                                      />
                                    )}
                                  </>
                                )}
                              </Flex>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={20}>
                              <DummyFeedback />
                            </Col>
                          </Row>
                        </div>
                      )
                    }
                  />
                  <p className="timer-desc">{time}</p>
                </List.Item>
              );
            }}
          />
        )}
      </div>
      {sapAiSuggetionQueList.length > 0 && (
        <div className="suggested-que-main" ref={suggetionQueMainRef}>
          <Title level={5} className="suggested-que-title">
            Suggested Questions:
          </Title>
          <Slider dots={true} arrows={false}>
            {renderSlides()}
          </Slider>
        </div>
      )}
      <Space.Compact className="ask-query-main">
        {/* <Button type='default' icon={<PaperClipOutlined />} onClick={showModal} disabled={user.plan === 'Free' && user.role !== 'admin'}></Button> */}
        <Button
          //type="primary"
          className="ask-query-icon audio"
          onClick={handleRecord}
          icon={<AudioOutlined />}
          danger={recording}
        />
        <Input
          value={newMessage}
          onChange={handleInputChange}
          onPressEnter={async () => {
            setChatInitiated(true);
            await handleSendMessage();
          }}
          placeholder="Ask your query..."
        />
        <Button
          //type="primary"
          className="ask-query-icon send"
          onClick={async () => {
            setChatInitiated(true);
            await handleSendMessage();
          }}
          icon={<SendOutlined />}
        />
      </Space.Compact>

      <div className='can-make-mistake-text'>
        Sanlam Virtual Assistant can make mistakes occasionally and may not always be precise; kindly verify before proceeding.
      </div>

      {/* <div style={{ textAlign: "center", fontSize: "10px", marginTop: "10px" }}>
        SAP AI Consultant can make mistakes. Raise a support ticket for any
        concern.
      </div> */}
      {/* <Switch onChange={onChangeSwitch1} checkedChildren="Web Search On" unCheckedChildren="Web Search Off" style={{marginTop: "15px"}} disabled={ user.plan === 'Free'? user.plan === 'Free' && user.role !== 'admin' : switch1Disabled} />
      <Switch className="desktop-view-inline" onChange={onChangeSwitch2} checkedChildren="Knowledge Base On" unCheckedChildren="Knowledge Base Off" style={{marginTop: "15px", marginLeft: "20px"}} disabled={user.plan === 'Free'? user.plan === 'Free' && user.role !== 'admin' : switch2Disabled} />
      <Switch className="mobile-view" onChange={onChangeSwitch2} checkedChildren="Knowledge Base On" unCheckedChildren="Knowledge Base Off" style={{marginTop: "15px"}} disabled={user.plan === 'Free'? user.plan === 'Free' && user.role !== 'admin' : switch2Disabled} /> */}
      <Modal
        title="Upload Your Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={600}
      >
        <Row style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Card style={{ padding: "10px" }}>
              <Dragger
                multiple
                beforeUpload={() => false} // Prevent automatic upload
                onChange={handleFileChange}
                fileList={fileList}
                accept=".pptx, .docx, .csv, .xlsx"
                maxCount={2}
              >
                <p className="ant-upload-drag-icon">
                  <FilePdfOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag DOCX, PPTX, XLSX, or CSV files to this area to
                  upload
                </p>
                <p className="ant-upload-hint">
                  Support for uploading up to 2 files.
                </p>
              </Dragger>
            </Card>
            <div
              className="desktop-view"
              style={{ textAlign: "right", marginTop: "30px" }}
            >
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                {isSubmitting
                  ? "Eerly AI Brain is processing your data. It might take up to a few minutes."
                  : "Upload"}
              </Button>
            </div>
            <div
              className="mobile-view"
              style={{ textAlign: "right", marginTop: "30px" }}
            >
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                {isSubmitting
                  ? "Eerly AI Brain is processing your data."
                  : "Upload"}
              </Button>
              {isSubmitting && (
                <Alert
                  style={{ marginTop: "10px" }}
                  message="It might take up to a few minutes."
                  type="info"
                  showIcon
                />
              )}
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Chatbot;
