// src/pages/Signup.tsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Form, Typography, Input, Button, Card, message, Checkbox, Modal } from 'antd';
import AppFooter from '../AppFooter/AppFooter';
import logo from '../../assets/images/Eerly_logo_color-transparent.png';
import { useAuth } from '../../auth/AuthContext';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Signup = () => {
  const businessEmailDomains = [
    "gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "aol.com",
    "zoho.com", "protonmail.com", "icloud.com", "gmx.com", "yandex.com",
    "mail.com", "tutanota.com", "lycos.com", "fastmail.com", "hushmail.com",
    "mailfence.com", "inbox.com", "rediffmail.com", "runbox.com",
    "mailbox.org", "posteo.net", "openmailbox.org"
  ];

  const [formData, setFormData] = useState({
    name: '',
    company_name: '',
    designation: '',
    official_email: '',
    username: '',
    password: '',
    confirm_password: ''
  });
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsChecked(true); // Set checkbox as checked when modal OK is clicked
    setIsDisabled(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsChecked(false); // Uncheck checkbox when modal is canceled
    setIsDisabled(false);
  };

  const handleCheckboxChange = (e) => {
    // Prevent default checkbox behavior
    e.preventDefault();
    showModal();
  };
  
  const auth = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
      // ...(name === "official_email" && { username: value.split('@')[0] })
    }));
    // name === "official_email" && form.setFieldsValue({ username: value.split('@')[0] });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await auth.register(formData);      
      navigate('/verify-email');
    } catch (error) {
      message.error('Registration failed');
    } finally {
      setLoading(false);
    }
  };
  
  const validateBusinessEmail = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Please input your company email!'));
    }
    const domain = value.split('@')[1];
    if (businessEmailDomains.includes(domain)) {
      return Promise.reject(new Error('Business email required, free email addresses are not allowed'));
    }
    return Promise.resolve();
  };

  const validatePassword = (_, value) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{8,}$/;
    if (!passwordPattern.test(value)) {
      return Promise.reject(new Error('Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character'));
    }
    return Promise.resolve();
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#E9E9EB" }}>
      <Content style={{ padding: '0', textAlign: "center" }}>
        <Row>
          <Col xs={0} sm={0} lg={7}></Col>
          <Col xs={24} sm={24} lg={10} style={{padding: "10px"}}>
            <Card style={{ marginTop: 100, padding: 30 }}>
              <img src={logo} alt="" width={"150px"} style={{ marginBottom: "20px" }} />
              <Title style={{ color: "#011470", marginTop: 0 }} level={4}>Create your Eerly AI Brain Account</Title>
              <Form layout="vertical" form={form} onFinish={handleSubmit}>
                <Row>
                  <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name!' }]}>
                      <Input name="name" value={formData.name} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Company Name" name="company_name" rules={[{ required: true, message: 'Please enter your company name!' }]}>
                      <Input name="company_name" value={formData.company_name} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Designation" name="designation" rules={[{ required: true, message: 'Please enter your designation!' }]}>
                      <Input name="designation" value={formData.designation} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item
                      label="Company Email"
                      name="official_email"
                      rules={[{ validator: validateBusinessEmail }, { required: true, message: 'Please enter your email!' }]}
                    >
                      <Input name="official_email" value={formData.official_email} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please enter your username!' }]}>
                      <Input name="username" value={formData.username} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[{ validator: validatePassword }, { required: true, message: 'Please enter your password!' }]}
                    >
                      <Input.Password name="password" value={formData.password} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Confirm Password" name="confirm_password" dependencies={['password']} rules={[
                      { required: true, message: 'Please confirm your password!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}>
                      <Input.Password name="confirm_password" value={formData.confirm_password} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px", marginTop: "20px", textAlign: "left"}}>
                    <Checkbox onClick={handleCheckboxChange} checked={isChecked}>I accept the <span onClick={showModal} style={{ color: 'blue', cursor: 'pointer' }}>Terms and Conditions</span>.</Checkbox>
                  </Col>
                  <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px", marginTop: "20px"}}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loading} disabled={!isDisabled}>
                        SIGN UP
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px", marginTop: "20px"}}>
                    <p className="font-semibold text-muted">
                      Already have an account?{" "}
                      <Link to="/login" className="text-dark font-bold">
                        Sign In
                      </Link>
                    </p>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col xs={0} sm={0} lg={7}></Col>
        </Row>
        <Modal title="Terms and Conditions" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} maskClosable={false} destroyOnClose centered width={1000}>
          <div style={{height: "80vh", overflowY: "auto"}}>
            <Typography>
              <Title level={1}>Terms and Conditions</Title>
              <Paragraph><b>Last Updated: 17/05/2024</b></Paragraph>

              <Title level={2}>1. Acceptance of Terms</Title>
              <Paragraph>
                By accessing or using Eerly AI Brain, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree to these terms, you may not use our software.
              </Paragraph>

              <Title level={2}>2. User Information</Title>
              <Paragraph>
                When you sign up for Eerly AI Brain, you may be asked to provide certain personally identifiable information (PII), including but not limited to your name, email address, and other contact details. You agree to provide accurate and current information and maintain the confidentiality of your login information.
              </Paragraph>

              <Title level={2}>3. Use of Data</Title>
              <Paragraph>
                Eerly AI Brain uses large language models to enhance workplace efficiency. We do not monetize your data or share it with third parties. However, we will use the data you provide for the following purposes:
                <ul>
                  <li>Future outreach and communication</li>
                  <li>Sending updates and newsletters</li>
                  <li>Improving and personalizing your experience with our software</li>
                </ul>
              </Paragraph>

              <Title level={2}>4. Intellectual Property</Title>
              <Paragraph>
                All content, trademarks, logos, and other intellectual property within Eerly AI Brain are the property of Eerly AI or its licensors. You agree not to reproduce, distribute, or create derivative works from any content without prior written consent from Eerly AI.
              </Paragraph>

              <Title level={2}>5. User Conduct</Title>
              <Paragraph>
                You agree to use Eerly AI Brain in compliance with all applicable laws and regulations. You are prohibited from using the software for any unlawful or unauthorized purpose, including but not limited to:
                <ul>
                  <li>Violating any local, state, national, or international law</li>
                  <li>Infringing on the intellectual property rights of others</li>
                  <li>Transmitting harmful code or interfering with the operation of the software</li>
                </ul>
              </Paragraph>

              <Title level={2}>6. Termination</Title>
              <Paragraph>
                Eerly AI reserves the right to terminate or suspend your access to Eerly AI Brain at our sole discretion, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users of the software, us, or third parties, or for any other reason.
              </Paragraph>

              <Title level={2}>7. Disclaimer of Warranties</Title>
              <Paragraph>
                Eerly AI Brain is provided "as is" and "as available" without any warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. Eerly AI does not warrant that the software will be uninterrupted, error-free, or secure.
              </Paragraph>

              <Title level={2}>8. Limitation of Liability</Title>
              <Paragraph>
                To the fullest extent permitted by applicable law, Eerly AI shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
                <ul>
                  <li>Your use of or inability to use the software</li>
                  <li>Any unauthorized access to or use of our servers and/or any personal information stored therein</li>
                  <li>Any interruption or cessation of transmission to or from the software</li>
                  <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our software by any third party</li>
                </ul>
              </Paragraph>

              <Title level={2}>9. Changes to Terms</Title>
              <Paragraph>
                Eerly AI reserves the right to modify these Terms and Conditions at any time. We will notify you of any changes by posting the new Terms and Conditions on our website. Your continued use of Eerly AI Brain after such changes constitutes your acceptance of the new Terms and Conditions.
              </Paragraph>

              <Title level={2}>10. Governing Law</Title>
              <Paragraph>
                These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which Eerly AI is headquartered, without regard to its conflict of law principles.
              </Paragraph>

              <Title level={2}>11. Contact Us</Title>
              <Paragraph>
                If you have any questions about these Terms and Conditions, please contact us at [contact email].
              </Paragraph>

              <Paragraph>
                By creating an account and using Eerly AI Brain, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
              </Paragraph>
            </Typography>
          </div>
        </Modal>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default Signup;
