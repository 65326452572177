import React, { useEffect, useState } from "react";
import "./SuggetionAndFaq.css";
import { Button, Divider, Flex, Input, Layout, List, Skeleton, Space, Typography } from "antd";
import {
    SendOutlined,
    AudioOutlined,
    DownOutlined
} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
const { Content } = Layout;
const { Title } = Typography;

const SuggetionAndFaq = () => {
    const [recording, setRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        fetch('https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo')
            .then((res) => res.json())
            .then((body) => {
                console.log(body, 'body')
                setData([...data, ...body.results]);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        loadMoreData();
    }, []);

    return (
        <Content
            style={{ overflow: "initial" }}
            className="site-layout-background mobile-layout-margin"
        >
            <Flex className="suggetion-faq-container" id="scrollableDiv">
                <Title level={2} className="suggetion-faq-title">
                    Hi, Welocome to SAP AI Consultant!
                </Title>
                <Flex className="suggetion-faq-opt-main">
                    <Title className="suggetion-faq-opt-title" level={4}>
                        Suggestions & FAQ
                    </Title>
                    <Flex gap={15}>
                        <Button className="suggetion-btn">FAQ</Button>
                        <Button className="suggetion-btn">Policy</Button>
                        <Button className="suggetion-btn">Procedures</Button>
                    </Flex>
                </Flex>
                <div style={{
                    //height: 400,
                    //overflow: 'auto',
                }}>
                    <InfiniteScroll
                        dataLength={data.length}
                        next={() => loadMoreData()}
                        hasMore={data.length < 50}
                        loader={
                            <div className="suggetion-faq-list-item">
                                <Skeleton
                                    paragraph={{
                                        rows: 1,
                                    }}
                                    active
                                />
                            </div>
                        }
                        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            className="suggetion-faq-list-main demo-loadmore-list"
                            dataSource={data}
                            itemLayout="horizontal"
                            renderItem={(item) => (
                                <List.Item className="suggetion-faq-list-item">
                                    <Title className="suggetion-faq-list-item-content" level={5}>{item.email}</Title>
                                </List.Item>
                            )}
                        />
                    </InfiniteScroll>
                </div>
                {/* <Flex className="view-more">
                    <Title level={5} className="view-more-title">View more</Title>
                    <DownOutlined />
                </Flex> */}
            </Flex>
            <Space.Compact className="ask-query-main">
                <Button
                    //type="primary"
                    className="ask-query-icon audio"
                    //onClick={handleRecord}
                    icon={<AudioOutlined />}
                //danger={recording}
                />
                <Input
                    // value={newMessage}
                    // onChange={handleInputChange}
                    // onPressEnter={async () => {
                    //     setChatInitiated(true);
                    //     await handleSendMessage();
                    // }}
                    placeholder="Ask your query..."
                />
                <Button
                    //type="primary"
                    className="ask-query-icon send"
                    // onClick={async () => {
                    //     setChatInitiated(true);
                    //     await handleSendMessage();
                    // }}
                    icon={<SendOutlined />}
                />
            </Space.Compact>
        </Content>
    );
};

export default SuggetionAndFaq;
