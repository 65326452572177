import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Switch,
  Tooltip,
  Radio,
  Flex,
} from "antd";
import Chatbot from "../../Chatbot/Chatbot";
import DownloadButton from "../../ButtonComponents/DownloadButton";
import ChatHistory from "../ChatHistory/ChatHistory";
const { Content } = Layout;
const { Title } = Typography;

const ChatWithData = ({
  messageList,
  setMessageList,
  suggestedQuestionsOn,
  setSuggestedQuestionsOn,
  sapAiMsgList,
  setSapAiMsgList,
  sapAiSuggetionQueList,
  setSapAiSuggetionQueList,
  isFirstQuestionChatAI,
  setIsFirstQuestionChatAI,
  chatWithAISessionID,
  setChatWithAISessionID,
  isFirstQuestionChatKB,
  setIsFirstQuestionChatKB,
  chatWithKBSessionID,
  setChatWithKBSessionID
}) => {
  const [initialized, setInitialized] = useState(false);
  const [value, setValue] = useState("New Chat");
  const options = [
    { label: "New Chat", value: "New Chat" },
    { label: "Chat History", value: "Chat History" },
  ];

  const onChange = ({ target: { value } }) => {
    console.log("radio3 checked", value);
    setValue(value);
  };

  const onChangeSwitch = (checked) => {
    setSuggestedQuestionsOn(checked);
  };

  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails && JSON.parse(userDetails);

  useEffect(() => {
    if (!initialized && user) {
      // if (user.plan !== 'Free' || user.role === 'admin') {
      //   setSuggestedQuestionsOn(true);
      // }
      setSuggestedQuestionsOn(true);
      setInitialized(true);
    }
  }, [user, initialized]);

  return (
    <Content
      style={{ overflow: "initial" }}
      className="site-layout-background mobile-layout-margin"
    >
      <Row>
        <Col span={24}>
          {value === "New Chat" ? (
            <Chatbot
              setMessageList={setMessageList}
              suggestedQuestionsOn={suggestedQuestionsOn}
              user={user}
              sapAiMsgList={sapAiMsgList}
              setSapAiMsgList={setSapAiMsgList}
              sapAiSuggetionQueList={sapAiSuggetionQueList}
              setSapAiSuggetionQueList={setSapAiSuggetionQueList}
              isFirstQuestionChatAI={isFirstQuestionChatAI}
              setIsFirstQuestionChatAI={setIsFirstQuestionChatAI}
              chatWithAISessionID={chatWithAISessionID}
              setChatWithAISessionID={setChatWithAISessionID}
              isFirstQuestionChatKB={isFirstQuestionChatKB}
              setIsFirstQuestionChatKB={setIsFirstQuestionChatKB}
              chatWithKBSessionID={chatWithKBSessionID}
              setChatWithKBSessionID={setChatWithKBSessionID}
            />
          ) : (
            <ChatHistory user={user} />
          )}
        </Col>
      </Row>
    </Content>
  );
};

export default ChatWithData;
