import React from 'react';
import { Upload, message, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

const PdfUploader = ({ onUpload, fileList, setFileList, loading, setLoading }) => {
  const handleFileChange = (info) => {
    const { file } = info;
    if (file.type !== 'application/pdf') {
      message.error(`${file.name} is not a PDF file`);
      return;
    }
    const updatedFileList = info.fileList.slice(-1); // Only keep the latest file
    setFileList(updatedFileList);
  };

  const handleSubmit = () => {
    if (!fileList.length) {
      message.error('Please upload a PDF file before submitting.');
      return;
    }
    setLoading(true)
    const file = fileList[0].originFileObj;
    onUpload(file);
  };

  return (
    <>
      <Upload.Dragger
        onChange={handleFileChange}
        fileList={fileList}
        showUploadList={true}
        accept=".pdf"
        beforeUpload={() => false} // Prevent automatic upload
      >
        <p className="ant-upload-drag-icon">
          <FilePdfOutlined />
        </p>
        <p className="ant-upload-text">Click or drag PDF file to this area to upload</p>
      </Upload.Dragger>
      <Button
        type="primary"
        onClick={handleSubmit}
        style={{ marginTop: '20px' }}
        disabled={!fileList.length}
        loading={loading}
      >
        Submit
      </Button>
    </>
  );
};

export default PdfUploader;
