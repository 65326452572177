import React, { useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation
} from "react-router-dom";
import AppFooter from "../AppFooter/AppFooter"
import { useAuth } from '../../auth/AuthContext';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const CheckoutForm = () => {
  const location = useLocation();
  const { plan } = location.state || {};
  localStorage.setItem('plan', JSON.stringify(plan));
  const auth = useAuth();

  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    const formData = new FormData();
    formData.append("price_id", plan.priceTier);
    formData.append("plan_name", plan.tier);
    return fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
      headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
      },
      method: "POST",
      body: formData
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = {fetchClientSecret};

  return (
    <div id="checkout" style={{padding: "3% 20px 20px 20px"}}>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
      <div style={{position: "fixed", bottom: 10, width: "100%"}}>
          <AppFooter />
      </div>
    </div>
  )
}

export default CheckoutForm;