import React from 'react';
import { Modal } from 'antd';
import PricingTable from './PricingTable/PricingTable';
import './PricingTableModal.css';

const PricingTableModal = ({visible, setVisible}) => {
    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    return (
        <div className={"pricing-modal"} >
        <Modal
            title="Pricing Plans"
            open={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            width="100%"
            centered
            // style={{ top: 0 }}
            // bodyStyle={{ height: '92vh', overflowY: 'auto', overflowX: 'hidden', padding:0 }}
            footer={null}
            className={"pricing-modal"} 
        >
            <PricingTable />
        </Modal>
        </div>
    )
}

export default PricingTableModal;