// src/pages/Login.tsx
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Form,
  Typography,
  Input,
  Button,
  Card,
  Alert,
} from "antd";
import AppFooter from "../AppFooter/AppFooter";
import bgImage from "../../assets/images/bg (6).jpg";
import logo from "../../assets/images/Sanlam.png";
import { useAuth } from "../../auth/AuthContext";

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

const Login = ({
  setMessageList,
  setSuggestedQuestionsOn,
  setSapAiMsgList,
  setSapAiSuggetionQueList,
  setSapHcPoliciesList,
  initialMessages
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    // Redirect to the dashboard if the user is already logged in
    if (auth.user) {
      navigate("/hc-policies-assistant");
    }
  }, [auth.user, navigate]);

  const handleSubmit = async () => {
    setLoading(true);
    setMessageList([]);
    setSuggestedQuestionsOn(false);
    setSapAiMsgList(initialMessages);
    setSapAiSuggetionQueList([]);
    setSapHcPoliciesList(initialMessages);
    // event.preventDefault();
    try {
      const newUser = await auth.login(username, password);
      setLoading(false);
      const firstLogin = newUser.first_login;
      if (firstLogin) navigate("/change-password");
      else navigate("/hc-policies-assistant");
    } catch (error) {
      console.error("Login failed:", error);
      setLoading(false);
      // Optionally handle login failure (e.g., displaying an error message)
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#E9E9EB" }}>
      {/* <img src={bgImage} alt="" style={{position: "absolute" , top: 0, left: 0, height: "100vh", width: "auto"}} /> */}

      <Content style={{ padding: "0", textAlign: "center" }}>
        {/* <Row>
          <Col xs={24}>
            <Alert message="Eerly AI Brain is currently down for scheduled maintenance. We'll be back soon. Thank you for your patience!" type="error" style={{position: "fixed", width: "100%", top: 0, zIndex: 1056}} />
          </Col>
        </Row> */}
        <Row justify="center" align="middle">
          <Col lg={{ span: 2 }} xs={{ span: 0 }}></Col>
          <Col
            lg={{ span: 6 }}
            xs={{ span: 24 }}
            style={{ paddingTop: "10vh", padding: "20px" }}
          >
            <Card
              style={{
                padding: "30px",
                boxShadow: "0px 0px 23px -1px rgba(133,133,133,1)",
              }}
            >
              {/* <Title className="mb-15">Sign In</Title> */}
              <img src={logo} alt="" width={"150px"} />
              <Title
                style={{
                  marginTop: "-10px",
                  marginBottom: "50px",
                  color: "#011470",
                }}
                level={4}
              >
                Please Login to Sanlam Virtual Assistant
              </Title>

              {/* <Title className="font-regular text-muted" level={5}>
                Enter your username and password to sign in
              </Title> */}
              <Form
                style={{ textAlign: "left" }}
                layout="vertical"
                onFinish={handleSubmit}
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                {/* <p className="font-semibold text-muted" style={{textAlign: "right", marginBottom: '-15px', marginTop: "-10px"}}>
                  <Link to="/forgot-password" className="text-dark font-bold">
                    Forgot Password?
                  </Link>
                </p> */}

                <Form.Item>
                  <Button
                    type="primary"
                    style={{ width: "100%", marginTop: "30px" }}
                    htmlType="submit"
                    // onClick={()=>handleSubmit()}
                    loading={loading}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
                {/* <p className="font-semibold text-muted" style={{textAlign: "center"}}>
                  Don't have an account?{" "}
                  <Link to="/signup" className="text-dark font-bold">
                    Sign Up
                  </Link>
                </p> */}
                {/* <p className="font-semibold text-muted" style={{textAlign: "center"}}>
                  Do you want to try Eerly AI Brain?{" "}
                  <Link to="/subscribe" className="text-dark font-bold">
                    Subscribe Now!
                  </Link>
                </p> */}
              </Form>
            </Card>
          </Col>
          <Col lg={{ span: 16 }} xs={{ span: 0 }} style={{ paddingTop: "5%" }}>
            <img src={bgImage} alt="" width={"100%"} />
          </Col>
        </Row>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default Login;
